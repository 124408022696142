@import '../node_modules/bulma/css/bulma.min.css';
/* @import './node_modules/nprogress/nprogress.css'; */
@import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

body {
  background: white;
  min-height: 100vh;
  padding-bottom: 15px;
  overflow: hidden;
}
.navbar {
  background-color: transparent;
}
.box {
  border-radius: 10px;
  box-shadow: 0 0.1em 1em -0.5em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  background-color: #2EA6DA;
  color: white;
  padding: 0.5rem;
}

.box .label, .box .checkbox, .box a, .box .title {
  color: white;
}

.box a:hover {
  text-decoration: underline;
}

.box .filepond--panel-root {
  background-color: white;
}

.box .input, .box .textarea, .box .select select, .box .button {
  border-color: #2ea6da;
}

.box .pagination-link.is-current {
  background-color: #797070;
  border-color: #ffffff;
  color: #4b8bff;
}
.box .notification {
  color: #363636;
}
.control .react-datepicker-wrapper {
  display: block;
}

@media screen and (max-width: 1023px) {
  main, footer {
    padding-left: 5px;
    padding-right: 5px;
  }
  .home-column-2 {
    padding-top: 0;
  }
  .mobile-text-center {
    text-align: center;
  }
}


section .title {
  text-align: center;
}
section:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title:not(:last-child), .box:not(:last-child) {
  margin-bottom: 0.5rem;
}

.navbar-burger {
  color: red;
}
.navbar-item a {
  color:#737373;
}
.navbar-burger span {
  height: 3px;
  border-radius: 10px;
  width: 20px;
  left: calc(50% - 10px);
}

.navbar-burger span:nth-child(1) {
  top: calc(50% - 8px);
}
.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}
.navbar-burger span:nth-child(3) {
  top: calc(50% + 6px);
}

.navbar-burger:hover {
  background-color: transparent;
  color: #f36161;
}

.breadcrumb a {
  text-decoration: none;
}

.box strong {
  color: white;
}

.has-text-danger-dark {
  color: red!important;
}

.Tenders a {
  text-decoration: underline;
}

.box .title {
  text-align: center;
}

button:disabled {
  background: #c3c3c3;
  color: #e6e6e6;
  opacity: 1;
}

.box.style2 {
  border: 4px solid #2fa6da;
  background: white;
  color: #006a97;
}

.box.style2 strong, .box.style2 a {
  color: #006a97;
}

.filepond--wrapper {
  max-width: 400px;
  margin: 0 auto;
}

.navbar-item a {
  color: #232323;
}

form .andor {
  text-align: end;
  font-weight: bold;
  margin-bottom: -20px;
  position: relative;
  max-width: 190px;
  left: 70px;
}

.andor::before, .andor::after {
  content: '';
  position: absolute;
  display: block;
  width: 4px;
  border-radius: 2px;
  background: white;
  right: 25px;
}

.andor::before {
  height: 14px;
  top: -10px;
}

.andor::after {
  height: 14px;
  bottom: -11px;
}

.phone-fullwidth {
  width: 100%;
}
/* tab Category*/ 
.category-tab {
  display: flex;
  margin-bottom: 5px;
}
.category-select select {
  border: 1px solid;
  /* border-color: #dbdbdb !important;
  border-top: 1px solid white !important;
  border-radius: 0 0 5px 5px; */
}
.category-tab .tab {
  padding: 5px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  color: black;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
  border-radius: 5px 5px 0 0;
}
.category-tab .tab:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.category-tab .active  {
  background: white;
  border: 1px solid #dbdbdb;
  border-bottom: 1px solid transparent;
}

.category-tab .active::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 99%;
  height: 3px;
  background: #dbdbdb;
}
form .category {
  display: none;
}
form .active-category {
  display: block;
}
.lock-icon {
  z-index: 0 !important;
}
/* Кнопка выпадающего списка */
.dropbtn {
  background-color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 20px;
}
.dropdown {
  align-self: center;
  position: absolute;
  right: 150px;
  border: 1px solid rgb(197, 197, 197);
  padding: 5px;
  border-radius: 5px;
}
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

@media (max-width: 676px) {
  .dropdown {
    right: 50px;
  }
  .dropbtn {
    width: 40px;
  }
}
#ruSelect {
  background-image: url(./images/RU.png);
}
#kySelect {
  background-image: url(./images/KG3.png);
}
#kzSelect {
  background-image: url(./images/KZ11.png);
}
.flagButton {
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
#ruButton {
  background-image: url(./images/RU.png);
}
#kyButton {
  background-image: url(./images/KG3.png);
}
#kzButton {
  background-image: url(./images/KZ11.png);
}
.dropdown-content button {
  background-color: white;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
}
.dropdown-content button:hover {background-color: #ddd;}
.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .dropbtn {background-color: #3e8e41;}
.tender-info a {
  display: block;
}
.tender-whatsapp, .tender-phone {
  color: #00587e;
}
.actual-title {
  text-decoration: none !important;
  color: #00587e !important;
}
.actual-title:hover {
  text-decoration: underline !important;
  color: #00587e !important;
}
