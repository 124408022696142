.Spinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 15px;      
}

.Spinner.is-fullscreen {
  height: calc(100vh - 15px);
}

.Spinner-loading {
  width: 2em;
  height: 2em;
}

.Spinner-caption {
  width: 100%;
  text-align: center;
  color: #949494;
  font-size: 14px;
  margin-top: 6px;
}