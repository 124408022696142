/* .logo {
  height: 22px;
} */
.wrapperLangSelect {
  align-self: center;
  position: absolute;
  right: 150px;
}
.wrapperLangSelect .langSelect {
  background-color: white;
  color: black;
  padding: 7px;
  width: 100px;
  border: none;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid rgb(199, 199, 199);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.wrapperLangSelect .langSelect::before {
  content: "\f13a";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 80%;
  text-align: center;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.wrapperLangSelect:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.wrapperLangSelect .langSelect option {
  padding: 30px;
}
@media (max-width: 676px) {
  .wrapperLangSelect {
    right: 45px;
  }
  .wrapperLangSelect .langSelect {
    height: 40px;
    width: 50px;
    padding: 0;
    font-size: 14px;
  }
}
.navbarItem img {
  width: 350px;
  max-height: 100%;
}
.navbar-menu.is-active {
  position: absolute;
  left: 0;
  right: 0;
}
@media (max-width: 676px) { 
  .navbarItem img { width: 300px;}
 }
@media (max-width: 480px) { 
  .navbarItem img { width: 240px;}
}