@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.wrap {
  overflow: hidden;
  color: red;
  border: 4px solid #2ea6da;
  box-sizing: content-box;
  font-size: 20px;
  box-sizing: content-box;
  box-shadow: rgba(10, 10, 10, 0.1) 0px 0.1em 1em -0.5em, rgba(10, 10, 10, 0.02) 0px 0px 0px 1px;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

.wrap .ticker {
  display: inline-block;
  height: 2.5rem;
  line-height: 2.5rem;
  white-space: pre;
  padding-left: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 18s;
  animation-duration: 18s;
}

.separator {
  width: 150px;
  display: inline-block;
}